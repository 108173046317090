<template>
  <v-dialog
    v-model="$store.state.requestConstultBrokerModal"
    width="500"
    persistent
  >
    <v-card class="rounded-lg">
      <v-toolbar dark color="primary" elevation="0">
        <h2 class="">Broker approval request</h2>
        <v-spacer></v-spacer>
        <v-btn color="white" icon @click="closeModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <br>
      <v-card-text>
        <v-form v-model="formData.form">
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                disabled
                outlined
                v-model="formData.broker_mcnumber"
                label="Broker MC/USDOT number"
                type="number"
                :rules="[rules.req]"
              ></v-text-field>
            </v-col>
           
            <v-col cols="12">
              <v-text-field
                outlined
                v-model="formData.broker_name"
                label="Broker name"
                :rules="[rules.req]"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn elevation="0" class="px-2" @click="closeModal()" large
          >Cancel</v-btn
        >
        <v-btn
          color="primary"
          elevation="0"
          @click="registerRequest()"
          large
          :disabled="!formData.form"
          >Request</v-btn
        >
        <br />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "axios";
import { mapMutations } from "vuex";

export default {
  props: ["modal", "rules", "broker_number"],
  data: () => ({
    ip: process.env.VUE_APP_API_URL,
    formData: {
      form: false,
      broker_name: "",
      broker_mcnumber: "" 
    },
  }),
  methods: {
    ...mapMutations(["showLoading", "hideLoading", "openAlert"]),
    registerRequest: function () {
      this.showLoading();
      this.closeModal();
      this.formData.phoneRequest = localStorage.getItem("phoneRequest");
      this.formData.emailRequest = localStorage.getItem("emailRequest");
      
      const BASE_URL = `${this.ip}request-consult-broker`;
      axios
        .post(BASE_URL, this.formData)
        .then((res) => {
          this.hideLoading();
          if (res.data.status == true) {
            const optionsAler = {
              message: "Credit search request received. We will notify our decision to " + this.formData.emailRequest + ' and ' +this.formData.phoneRequest + '.',
              action: "refresh",
              path: `/`,
            };

            

            this.openAlert(optionsAler);
          }
        })
        .catch((err) => {
          const optionsAler = {
            message: "Error in server, please contact the administrator.",
            action: "close",
            path: "/",
          };
          this.openAlert(optionsAler);
          this.openModal();
          console.log(err);
        });
    },
    closeModal: function () {
      this.$store.state.requestConstultBrokerModal = false;
    },
    openModal: function () {
      this.$store.state.requestConstultBrokerModal = true;
    },
  },
  mounted() {
    this.formData.broker_mcnumber = this.broker_number;
  },
};
</script>
