import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'


// moneda
import VueCurrencyInput from "vue-currency-input";
Vue.use(VueCurrencyInput);

import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'

Vue.config.productionTip = false;


//Internationalization
import VueI18n from 'vue-i18n'
import en from './plugins/i18n/en'
import es from './plugins/i18n/es'

// INICIO - Internationalizacion 
Vue.use(VueI18n);

const messages = {
    en: en,
    es: es
};

const i18n = new VueI18n({
    locale: 'en', //cambia el idioma.
    messages
});

// FIN - Internationalizacion 

new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: h => h(App)
}).$mount('#app')