<template>
    <div>
        <v-dialog v-model="$store.state.modalCreateBrokerVar" width="550px" persistent>
            <v-card v-if="showModal == true">
                <v-card-title>
                    <strong class="primary--text">
                        Create broker
                    </strong>
                </v-card-title> 
                <v-card-text>
                    <v-form v-model="formData.form">
                        <v-text-field outlined label="Broker name" :disabled="requestBroker!=null" v-model="formData.brokerName"></v-text-field>
                        <v-text-field outlined label="Broker mc number" :disabled="requestBroker!=null" v-model="formData.brokerMcNumber"></v-text-field>
                        <v-text-field outlined label="Usdot number"  v-model="formData.usdotNumber"></v-text-field>
                        <v-select outlined label="Credit report" :items="itemsCreditReport" v-model="formData.creditReport"></v-select>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn elevation="0" @click="closeModal()">Cancel</v-btn>
                    <v-btn elevation="6" color="primary" @click="createBroker()" :disabled="formData.form == false">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>

import axios from "axios";
import { mapMutations } from "vuex";

export default {
    props: ['requestBroker'],
    data: () => ({
        ip: process.env.VUE_APP_API_URL,
        showModal: true,
        formData: {
            form: false,
            brokerName: '',
            brokerMcNumber: '',
            creditReport: 1,
            idRequest: '',
            emailRequest: '',
            phoneRequest: '',
        },
        itemsCreditReport: [
            {text: 'Approved', value: 1},
            {text: 'Not Approved', value: 0}
        ]
    }),
    methods:
     {
        ...mapMutations(["showLoading", "hideLoading", "openAlert"]),
        createBroker: function(){
            this.showModal = false;
            this.showLoading();
            const BASE_URL = `${this.ip}create-broker`;
            axios.post(BASE_URL, this.formData).then(res => {
                this.hideLoading();
                this.$store.state.modalCreateBrokerVar = false;
                const optionsAler = {
                    message: res.data.message,
                    action: "close",
                    path: `/`,
                };
                this.openAlert(optionsAler);
                this.$emit('updatePage');
            }).catch(err => {
                this.hideLoading();
                this.showModal = true;
                console.log(err);
            })
        },
        closeModal:function(){
            this.$store.state.modalCreateBrokerVar = false;
        }
    },
    mounted() {
        if(this.requestBroker != null){
            this.formData.brokerName = this.requestBroker.brokerName;
            this.formData.brokerMcNumber = this.requestBroker.brokerMcNumber;
            this.formData.idRequest = this.requestBroker.idRequest;
            this.formData.emailRequest = this.requestBroker.emailRequest;
            this.formData.phoneRequest = this.requestBroker.phoneRequest;
        }
    }
}
</script>