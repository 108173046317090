<template>
    <v-dialog v-model="$store.state.modalEditBroker" width="550px" persistent>
        <v-card v-if="showCard==true">
            <v-card-title>
                <strong class="primary--text">Update broker</strong>
            </v-card-title> 
            <v-card-text>
                <v-form v-model="form">
                    <v-text-field outlined label="Name broker" class="input" v-model="broker.name"></v-text-field>
                    <v-text-field outlined label="Mc number broker" disabled class="input" v-model="broker.mc_number"></v-text-field>
                    <v-text-field outlined label="Usdot broker" disabled class="input" v-model="broker.usdot_number"></v-text-field>
                    <v-select outlined label="Credit report" :items="itemsCreditReport" v-model="broker.credit_report_status" class="input"></v-select>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn elevation="0" @click="closeModal()">Cancel</v-btn>
                <v-btn elevation="6" color="primary" :disabled="form == false" @click="updateInformation()">Save</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import axios from "axios";
import { mapMutations } from "vuex";

export default {
    props: ['broker'],
    data: () => ({
        ip: process.env.VUE_APP_API_URL,
        showCard: true,
        form: false,
        itemsCreditReport: [
            {text: 'Approved', value: 1},
            {text: 'Not Approved', value: 0}
        ]
    }),
    methods: {
        ...mapMutations(["showLoading", "hideLoading", "openAlert"]),
        closeModal:function(){
            this.$store.state.modalEditBroker = false;
        },
        updateInformation: function(){
            this.showLoading();
            this.showCard = false;
            const BASE_URL = `${this.ip}update-broker`;
            axios.post(BASE_URL, this.broker).then(res => {
                this.hideLoading();
                this.$store.state.modalEditBroker = false;
                const optionsAler = {
                    message: res.data.message,
                    action: "close",
                    path: `/`,
                };
                this.openAlert(optionsAler);
            }).catch(err => {
                this.hideLoading();
                this.showCard = true;
                console.log(err);
            })
        }
    },
    mounted(){

    }
}
</script>
<style scoped>
.input {
  margin-bottom: -12px;
}
</style>
